<template>
  <div class="py-3 px-5 my-5" style="width: 100%; background-color: #ffffff77; border-radius: 8px">
    <div style="cursor: pointer">
      <c-flex align="center" justify="space-between">
        <c-flex @click="goToProfile(person.id)">
          <c-image
              style="border-radius: 5px"
              h="40px"
              w="40px"
              mr="3"
              alt="profile picture"
              :src="require('@/assets/img/user.jpg')"
          />
          <c-flex justify="center" align="start" flexDirection="column">
            <c-text fontWeight="500" fontSize="sm">{{ person.firstname }} {{ person.lastname }}</c-text>
            <c-flex >
<!--              <c-text :key="itm.id" v-for="itm in person.item.company" fontWeight="400" fontSize="md" mr="5"> {{ itm }} </c-text>-->
            </c-flex>
            <c-text fontWeight="400" fontSize="xs"> {{ followersAggregate }} Followers </c-text>
          </c-flex>
        </c-flex>
        <div>
          <c-flex>
            <c-button @click="sendQuickMessage(person)" size="xs" variant-color="vc-orange" variant="outline" mb="10px">Message</c-button>
            <c-button @click="follow" size="xs" ml="5" variant-color="vc-orange"> {{ followed || followingUser ? 'Following' : 'Follow' }} </c-button>
          </c-flex>
        </div>
      </c-flex>
    </div>
  </div>
</template>

<script>


import {followUser, getUserFollowersAggregate, unFollowUser} from "@/services/user";
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import {getOneOnOneMessages} from "@/services/messages";

export default {
  name: 'BusinessCard',
  props: ['person'],
  data(){
    return {
      followersAggregate: 0,
      followed: false,
      btnLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getUser"
    }),
    followingUser(){
      return this.currentUser.user_followers.some((flw) => flw.following.id === this.person.id)
    },
  },
  methods: {
    ...mapActions({
      addQuickChat: 'messaging/addQuickChat',
    }),
    async sendQuickMessage(user) {
      const loggedInUser = store.state.auth.user.id;
      const profileUserId = user.id;
      const limit = 20;
      const offset = 0;

      try {
        const response = await getOneOnOneMessages({
          loggedInUser,
          profileUserId,
          limit,
          offset,
        });

        const historyChats = response.data.personal_chat_message;

        user.messages = historyChats;

        // if (historyChats.length > 0)
        //   user.relationshipId = historyChats[0].relationshipId;

        console.log('second', user);
        this.addQuickChat(user);
      } catch (error) {
        console.log(error);
      }
    },
    async getUserFollowersAggregate() {
      await getUserFollowersAggregate(this.person.id)
          .then((res) => {
            this.followersAggregate = res.data.user_follower_aggregate.aggregate.count
          }).catch((e) => {
            console.log(e)
          })
    },
    async follow() {
      this.btnLoading = true;
      if(this.followed || this.followingUser){
        try {
          await unFollowUser(this.person.id, this.currentUser.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = false;
          this.followersAggregate += 1;
        }catch (e){
          this.btnLoading = false
        }
      }
      else{
        try {
          await followUser(this.person.id);

          const audio = new Audio(require('@/assets/sounds/tone.mp3'));
          await audio.play();
          this.btnLoading = false;
          this.followed = true;
          this.followersAggregate -= 1;
        } catch (e) {
          this.btnLoading = false;
          console.log(e);
        }
      }
    },
    goToProfile(userId) {
      console.log(userId);
      this.$router.push({ name: 'Profile', params: { userId } });
    },
  },

  mounted() {
    this.getUserFollowersAggregate()
  }
}
</script>

<style scoped>

</style>